const stringHash = require("string-hash");

// this function will work cross-browser for loading scripts asynchronously
// function inspired from: https://gist.github.com/james2doyle/28a59f8692cec6f334773007b31a1523
function loadScript(src: string) {
    return new Promise(function (resolve, reject) {
        const scriptID = stringHash(src)

        // prevent same script from loading more than once
        const el = document.getElementById(scriptID)
        if (el) {
            resolve(el);
            return;
        }

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.id = scriptID;
        s.src = src;
        // s.async = true;
        s.onerror = function (err) {
            // console.log("PayEngine Module Not Loaded")
            reject(err);
        };
        s.onload = function () {
            // console.log("PayEngine Loaded")
            // resolve(window.PayEngine); 
            resolve(s);

        };
        document.head.appendChild(s)
    });
}

export { loadScript };